import React from 'react';
import headerIcon from '../images/iconcropped.png';

const Header = ({ isSmall, onHeaderClick }) => {
  return (
    <header>
      <div className='header-icon-container' onClick={onHeaderClick}>
        <img src={headerIcon} alt="Header" className={`header-icon${isSmall ? '-small' : ''}`} />
      </div>
      <p className="header-description">中文溜溜溜 Chinese666 🀄</p>
      <p className="header-description">Free Mandarin & Cantonese Resources</p>
    </header>
  );
};

export default Header;
