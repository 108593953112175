import React, { useState } from 'react';
import PrivacyPolicy from './PrivacyPolicy'
import email from '../images/email.png';
import instagram from '../images/instagram.png';
import smile from '../images/smile_face.png';
import privacy from '../images/privacy.png';

const Footer = ({ showPrivacyPage }) => {

  const [showPrivacy, setShowPrivacy] = useState(showPrivacyPage ? showPrivacyPage : false);

  const showPrivacyPopup = (isShown) => {
    setShowPrivacy(isShown);
  };

  return (
    <footer>
      <div className="footer-logo">
        <img src={smile} alt="footer logo" />
      </div>
      <div className="footer-bottom">
        <div className="footer-contacts">
          <a href="mailto:manuhuang@outlook.com" target="_blank" rel="noreferrer">
            <img src={email} alt="email me" />
          </a>
          <a href="https://www.instagram.com/huangchineselousi/" target="_blank" rel="noreferrer">
            <img src={instagram} alt="instagram link" />
          </a>
          <span className="privacy-btn" onClick={ ()=> { showPrivacyPopup(true); } }>
            <img src={privacy} alt="privacy policy" />
          </span>
        </div>
        <div className="footer-copy-rights">
          <span>© 2023 huanglousi. All rights reserved.</span>
        </div>
        { showPrivacy && <PrivacyPolicy onClose={ ()=> { showPrivacyPopup(false); } } /> }
      </div>
    </footer>
  );
};

export default Footer;
