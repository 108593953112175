import React from 'react';
import Header from './Header';
import Footer from './Footer';

const AdditionalProductPopup = ({ showPopup, onClose }) => {

  const handlePopupClose = () => {
    onClose();
  }

  return (
    <div className={`popup ${showPopup ? 'show' : ''}`}>
      <button className="close-button" onClick={handlePopupClose}>X</button>
      <div className="popup-inner">
        <Header />
        <h2 className="popup-title">Test</h2>
        <Footer />
      </div>

    </div>
  );
};

export default AdditionalProductPopup;
