import React from 'react';

const Product = ({ product, onClickItem }) => {
  const handleProductClick = () => {
    onClickItem(product);
  };
  
  return (
    <div className="product" onClick={handleProductClick}>
      <img src={product.mainImage.src} alt={product.mainImage.altText}/>
      <div className="product-title">{product.name}</div>
      <div className="product-info">
        { product.language.length !== 0 && <div>Language: {product.language.join(', ')}</div> }
        { product.level.length !== 0 && <div>Level: {product.level.join(', ')}</div> }
        { product.focus.length !== 0 && <div>Focus: {product.focus.join(', ')}</div> }
      </div>
    </div>
  );
};

export default Product;