import { React, useEffect } from 'react';
import download from '../images/dl.gif';
import back from '../images/back.gif';
import { InstagramEmbed } from 'react-social-media-embed';
import analytics from "../GoogleAnalytics";
import { logEvent } from "firebase/analytics";

const ProductDetail = ({ selectedProduct, onBackButtonClick }) => {
  useEffect(() => {
    logEvent(analytics, selectedProduct.name);
  }, []);

  return (
    <div className="product-detail-container">
      <div className="product-detail">
        { selectedProduct.productType !== 'IB Oral Exam Practice' &&  <div className="product-detail-left">
          { selectedProduct.productType !== 'Daily Chinese' && <div className="product-detail-header">
            <h2 className="product-name">{selectedProduct.name}</h2>
          </div> }
          <div className="product-detail-images">
            <div className="main-image">
              <img src={selectedProduct.mainImage.src} alt={selectedProduct.mainImage.altText} />
            </div>
            { selectedProduct.smallImages && selectedProduct.smallImages.length > 0 && <div className="small-images">
              {selectedProduct.smallImages.map((smallImage, _) => (
                <img src={smallImage.src} alt={smallImage.altText} />
              ))}
            </div> }
          </div>
          { selectedProduct.instagramUrl && <div style={{ 'margin-top': '32px', display: 'flex', justifyContent: 'center' }}>
            <InstagramEmbed url={selectedProduct.instagramUrl} width={328} height={{}} />
          </div> }
          { selectedProduct.downloadUrl && <div className="download-button">
            <a href={selectedProduct.downloadUrl}>
              <img src={download} alt="download" />
            </a>
          </div>}
          { selectedProduct.extraItems }
        </div> }
        <div className="product-detail-right">
          <div className="product-description">{selectedProduct.description}</div>
        </div>
      </div>
      <div className="product-detail-bottom">
        <div className="back-button" onClick={onBackButtonClick}>
          <img src={back} alt="back" />
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
