import React, { useRef } from 'react';

const BodyItem = ({ imageSrc, gifSrc, onClickItem, productType }) => {
  const imageRef = useRef(null);

  const handleClickItem = () => {
    onClickItem(productType);
  };

  const startAnimation = () => {
    if (imageRef.current) {
      imageRef.current.src = gifSrc;
    }
  };

  const stopAnimation = () => {
    if (imageRef.current) {
      imageRef.current.src = imageSrc;
    }
  };

  return (
    <div className="body-item" onClick={handleClickItem} onMouseEnter={() => startAnimation()} onMouseLeave={() => stopAnimation()}>
      <img src={imageSrc} alt="body item" ref={imageRef} />
    </div>
  );
};

export default BodyItem;