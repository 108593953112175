import './css/App.css';
import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Header isSmall={false}/>
      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/privacy" element={<Body />} />
        <Route path="/product/:productIndex" element={<Body />} />
      </Routes>
      <Routes>
        <Route path="/" element={<Footer />} />
        <Route path="/privacy" element={<Footer showPrivacyPage={true} />} />
      </Routes>
    </div>
  );
}

export default App;
