import React from 'react';
import logo from '../images/logo.svg';
import Product from './Product';

const ProductList = ({ products, onProductSelected }) => {
  const handleProductClick = (product) => {
    onProductSelected(product);
  };

  return (
    <div className="product-list-container">
      {products.map((product, index) => (
        <Product
          key={index}
          product={product}
          imgSrc={logo}
          onClickItem={handleProductClick} />
      ))}
    </div>
  );
};

export default ProductList;