import React from 'react';

const Dropdown = ({ options, hint, onChange }) => {
  return (
    <div className="drowdown">
        <select onChange={onChange} defaultValue="" >
          <option key='0' value="" disabled hidden>{hint}</option>
          {options.map((option, index) => (
            <option key={index + 1} value={option}>{option}</option>
          ))}
        </select>
    </div>
  );
};

export default Dropdown;