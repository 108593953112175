import React, { useState, useEffect, useRef } from 'react';
import Dropdown from './Dropdown';
import ProductList from './ProductList';
import ProductDetail from './ProductDetail';
import Header from './Header';
import Footer from './Footer';
import closeButton from '../images/close.png';
import back from '../images/back.gif';

const Popup = ({ showPopup, onClose, productList, productType, productId }) => {

  const popupRef = useRef(null);
  
  const focusList = ['All Focuses', 'Reading', 'Writing', 'Speaking', 'Listening']
  const levelList = ['All Levels', 'Beginner', 'Intermediate', 'Advanced']
  const languageList = ['All Languages', 'Mandarin-Traditional', 'Mandarin-Simplified', 'Cantonese']

  // Apply filters to the product data
  const filteredProducts = (products) => {
    return Object.values(products).filter((product) => {
      const isFocusMatch = focusFilter === 'All Focuses' || product.focus.includes(focusFilter);
      const isLevelMatch = levelFilter === 'All Levels' || product.level.includes(levelFilter);
      const isLanguageMatch = languageFilter === 'All Languages' || product.language.includes(languageFilter);
      const isProductTypeMatch = product.productType === productType;

      return isProductTypeMatch && isFocusMatch && isLevelMatch && isLanguageMatch;
    });
  }
  
  const [focusFilter, setFocusFilter] = useState(focusList[0]);
  const [levelFilter, setLevelFilter] = useState(levelList[0]);
  const [languageFilter, setLanguageFilter] = useState(languageList[0]);
  const [products, setProducts] = useState(filteredProducts(productList));
  const [selectedProduct, setSelectedProduct] = useState(productId ? productList[productId] : null);

  const handleFocusFilterChange = (event) => {
    setFocusFilter(event.target.value);
  };

  const handleLevelFilterChange = (event) => {
    setLevelFilter(event.target.value);
  };

  const handleLanguageFilterChange = (event) => {
    setLanguageFilter(event.target.value);
  };

  const handleProductSelected = (selectedProduct) => {
    setSelectedProduct(selectedProduct)
    if (popupRef.current) {
      popupRef.current.scrollTo(0, 0);
    }
  }

  const handleBackToProductList = () => {
    setSelectedProduct(null)
    if (popupRef.current) {
      popupRef.current.scrollTo(0, 0);
    }
  }

  useEffect(() => {
    setProducts(filteredProducts(productList));
  }, [focusFilter, levelFilter, languageFilter]);

  const handlePopupClose = () => {
    setLevelFilter(focusList[0]);
    setFocusFilter(focusList[0]);
    setLanguageFilter(languageList[0]);
    onClose();
  };

  return (
    <div className={`popup ${showPopup ? 'show' : ''}`}>
      <div className="popup-inner" ref={popupRef}>
        { selectedProduct != null && <div className="back-button fixed-top-left" onClick={handleBackToProductList}>
          <img src={back} alt="back" />
        </div> }
        <div className="close-button" onClick={handlePopupClose}>
          <img src={closeButton} alt="Close Button"/>
        </div>
        <Header isSmall={selectedProduct != null} onHeaderClick={onClose}/>
        { selectedProduct == null && <h2 className="popup-title">{productType}</h2> }
        { selectedProduct == null && productType !== 'IB Oral Exam Practice' 
          && productType !== 'Coloring' && <div className="filters">
          <Dropdown
            options={languageList}
            hint={'By Language'}
            onChange={handleLanguageFilterChange}
          />
          <Dropdown
            options={levelList}
            hint={'By Level'}
            onChange={handleLevelFilterChange}
          />
          <Dropdown
            options={focusList}
            hint={'By Focus'}
            onChange={handleFocusFilterChange}
          />
        </div> }
        { selectedProduct == null && <ProductList products={products} onProductSelected={handleProductSelected} /> }
        { selectedProduct != null && <ProductDetail selectedProduct={selectedProduct} onBackButtonClick={handleBackToProductList} /> }
        <Footer />
      </div>
    </div>
  );
};

export default Popup;
