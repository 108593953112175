import React, { useState } from 'react';
import Popup from './Popup';
import AdditionalProductPopup from './AdditionalProductPopup';
import BodyItem from './BodyItem'
import AdditionalBodyItem from './AdditionalBodyItem';
import { useParams } from 'react-router-dom';


const Body = () => {
  // Sample product data
  const productList = {
    'lunar_new_year_trad': { 
      name: '小猪佩奇 Peppa Pig | 春季 Lunar New Year (Traditional)',
      language: ['Mandarin-Traditional'],
      productType: 'Shadowing',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Listening', 'Writing'],
      description: <div className={'product-description-images'}>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/QXYODkOz22g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <img src={'/images/Product_Posters/Shadowing/CNY_TRAD/0001.jpg'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Shadowing/CNY_TRAD/0002.jpg'} alt={'Chinese Speaking Practice'}/>
        <img src={'/images/Product_Posters/Shadowing/CNY_TRAD/0003.jpg'} alt={'Learn Mandarin by watching cartoon'}/>
        <img src={'/images/Product_Posters/Shadowing/CNY_TRAD/0004.jpg'} alt={'Mandarin free resource Anime'}/>
    </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1Iwhy8q8wZv9Hsr0Y5fqTPgOokRaBVoWL&export=download',
      mainImage: { src: '/images/Product_Posters/Shadowing/1.jpeg', altText: 'Chinese Speaking Practice' },
      smallImages: [],
      altText: 'Chinese Speaking Practice'
    },
    'lunar_new_year_simp': { 
      name: '小猪佩奇 Peppa Pig | 春季 Lunar New Year (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Shadowing',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Listening', 'Writing'],
      description: <div className={'product-description-images'}>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/QXYODkOz22g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <img src={'/images/Product_Posters/Shadowing/CNY_SIMP/0001.jpg'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Shadowing/CNY_SIMP/0002.jpg'} alt={'Chinese Speaking Practice'}/>
        <img src={'/images/Product_Posters/Shadowing/CNY_SIMP/0003.jpg'} alt={'Learn Mandarin by watching cartoon'}/>
        <img src={'/images/Product_Posters/Shadowing/CNY_SIMP/0004.jpg'} alt={'Mandarin free resource Anime'}/>
    </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1e4SvzWvIr9IpPegA-SzchL7HHpfiDwfZ&export=download',
      mainImage: { src: '/images/Product_Posters/Shadowing/1.jpeg', altText: 'Chinese Speaking Practice' },
      smallImages: [],
      altText: 'Chinese Speaking Practice'
    },
    'halloween': { 
      name: '小猪佩奇 Peppa Pig | 万圣节 Halloween (Traditional & Simplified)',
      language: ['Mandarin-Traditional', 'Mandarin-Simplified'],
      productType: 'Shadowing',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Listening', 'Writing'],
      description: <iframe src="https://docs.google.com/document/d/e/2PACX-1vSPu13RWS7ep9MX_-BdpQXUgfHHntrU44s51JKNheC8v9BxBRYQPjzJ2S-nVRurLizhY89zjedoj7_R/pub?embedded=true" title="Halloween"></iframe>,
      downloadUrl: 'https://drive.google.com/uc?id=1PWVtsNOhRH-M6ATA85ws9yDpRDbkei06&export=download',
      mainImage: { src: '/images/Product_Posters/Shadowing/3.jpeg', altText: 'Chinese Shadowing' },
      smallImages: [],
      altText: 'Chinese Speaking Practice'
    },
    'christmas': { 
      name: '小猪佩奇 Peppa Pig | 圣诞节 Christmas (Traditional & Simplified)',
      language: ['Mandarin-Traditional', 'Mandarin-Simplified'],
      productType: 'Shadowing',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Listening', 'Writing'],
      description: <iframe src="https://docs.google.com/document/d/e/2PACX-1vSPu13RWS7ep9MX_-BdpQXUgfHHntrU44s51JKNheC8v9BxBRYQPjzJ2S-nVRurLizhY89zjedoj7_R/pub?embedded=true" title="Christmas"></iframe>,
      downloadUrl: 'https://drive.google.com/uc?id=1XgJBZHJ6TF5QHohqhptJhOVktjTJb7hQ&export=download',
      mainImage: { src: '/images/Product_Posters/Shadowing/2.jpeg', altText: 'Mandarin Speaking Practice' },
      smallImages: [],
      altText: 'Chinese Speaking Practice'
    },
    'burnmyfat': { 
      name: '菜菜子 Caicaizi | 燃烧我的卡路里 Burn My Fat (Traditional & Simplified)',
      language: ['Mandarin-Traditional', 'Mandarin-Simplified'],
      productType: 'Shadowing',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Listening', 'Writing'],
      description: <iframe src="https://docs.google.com/document/d/e/2PACX-1vSPu13RWS7ep9MX_-BdpQXUgfHHntrU44s51JKNheC8v9BxBRYQPjzJ2S-nVRurLizhY89zjedoj7_R/pub?embedded=true" title="Burn My Fat"></iframe>,
      downloadUrl: 'https://drive.google.com/uc?id=1cjGzEPMksLwW4lAWHs0h_GjTkPXD4_6m&export=download',
      mainImage: { src: '/images/Product_Posters/Shadowing/BurnMyFat.jpeg', altText: 'Mandarin Shadowing' },
      smallImages: []
    },
    'awkward_silence': { 
      name: '菜菜子 Caicaizi | 最怕空气突然安静 Awkward Silence (Traditional & Simplified)',
      language: ['Mandarin-Traditional', 'Mandarin-Simplified'],
      productType: 'Shadowing',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Listening', 'Writing'],
      description: <iframe src="https://docs.google.com/document/d/e/2PACX-1vSPu13RWS7ep9MX_-BdpQXUgfHHntrU44s51JKNheC8v9BxBRYQPjzJ2S-nVRurLizhY89zjedoj7_R/pub?embedded=true" title="Awkward Silence"></iframe>,
      downloadUrl: 'https://drive.google.com/uc?id=1dNkT2LjJzU0lJ__BAh_3p7GNlJcpWfd0&export=download',
      mainImage: { src: '/images/Product_Posters/Shadowing/AwkwardSilence.jpeg', altText: 'Mandarin homeschool' },
      smallImages: []
    },
    'caicaizi_banzhuan': { 
      name: '菜菜子 Caicaizi | 梦还得做砖还得搬 You Need to Dream But You Still Have to Work (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Shadowing',
      level: ['Intermediate', 'Advanced'],
      focus: ['Speaking', 'Reading', 'Listening', 'Writing'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/Shadowing/BanZhuan/Simp/01.gif'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Shadowing/BanZhuan/Simp/02.gif'} alt={'Chinese Speaking Practice'}/>
        <img src={'/images/Product_Posters/Shadowing/BanZhuan/Simp/03.gif'} alt={'Learn Mandarin by watching cartoon'}/>
        <img src={'/images/Product_Posters/Shadowing/BanZhuan/Simp/04.gif'} alt={'Mandarin free resource'}/>
        <img src={'/images/Product_Posters/Shadowing/BanZhuan/Simp/05.gif'} alt={'Mandarin homeschool'}/>
        <img src={'/images/Product_Posters/Shadowing/BanZhuan/Simp/06.gif'} alt={'Chinese Shadowing'}/>
        <img src={'/images/Product_Posters/Shadowing/BanZhuan/Simp/07.gif'} alt={'Chinese Speaking Practice'}/>
        <img src={'/images/Product_Posters/Shadowing/BanZhuan/Simp/08.gif'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Shadowing/BanZhuan/Simp/09.gif'} alt={'Mandarin Shadowing'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1oUmd2Oiht-ZBHgWRkBLmPeizzowQv8Sb&export=download',
      mainImage: { src: '/images/Product_Posters/Shadowing/BanZhuan/BanZhuan.png', altText: 'Chinese Shadowing' },
      smallImages: [],
      altText: 'Chinese Speaking Practice'
    },
    'cultural_comparison_simp': {
      name: '对比文化差异 Cultural Comparison (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Others',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Writing'],
      description: <div>
        <div className={'product-description-images'}>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Simp/01.jpeg'} alt={'Mandarin Cultures'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Simp/02.jpeg'} alt={'HSK3 Grammar'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Simp/03.jpeg'} alt={'Mandarin Cultures'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Simp/04.jpeg'} alt={'HSK3 Grammar'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Simp/05.jpeg'} alt={'Mandarin Cultures'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Simp/06.jpeg'} alt={'HSK3 Grammar'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Simp/07.jpeg'} alt={'Mandarin Cultures'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Simp/08.jpeg'} alt={'HSK3 Grammar'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Simp/09.jpeg'} alt={'Mandarin Cultures'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Simp/10.jpeg'} alt={'HSK3 Grammar'}/>
        </div>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=144QV6M9dk2VWr9CIhaGfMUipzySA10FV&export=download',
      mainImage: { src: '/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Cultural_Comparison_Mandarin.jpeg', altText: 'Mandarin free resource' },
      smallImages: []
    },
    'cultural_comparison_trad': {
      name: '对比文化差异 Cultural Comparison (Traditional)',
      language: ['Mandarin-Traditional'],
      productType: 'Others',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Writing'],
      description: <div>
        <div className={'product-description-images'}>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Trad/01.jpeg'} alt={'Mandarin Cultures'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Trad/02.jpeg'} alt={'HSK3 Grammar'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Trad/03.jpeg'} alt={'Mandarin Cultures'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Trad/04.jpeg'} alt={'HSK3 Grammar'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Trad/05.jpeg'} alt={'Mandarin Cultures'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Trad/06.jpeg'} alt={'HSK3 Grammar'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Trad/07.jpeg'} alt={'Mandarin Cultures'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Trad/08.jpeg'} alt={'HSK3 Grammar'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Trad/09.jpeg'} alt={'Mandarin Cultures'}/>
          <img src={'/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Trad/10.jpeg'} alt={'HSK3 Grammar'}/>
        </div>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1WDR2Aq4QvFTv4WUJt-LPEybbRmjyPovI&export=download',
      mainImage: { src: '/images/Product_Posters/Others/Cultural_Comparison_Mandarin/Cultural_Comparison_Mandarin.jpeg', altText: 'Mandarin free resource' },
      smallImages: []
    },
    'cultural_comparison_cantonese': { 
      name: '對比文化差異 Cultural Comparison (Traditional)',
      language: ['Cantonese'],
      productType: 'Others',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Writing'],
      description: <iframe src="https://docs.google.com/document/d/e/2PACX-1vSv7nFI_bnjFBWm4gBHhiMDxeDpoTOqe5t9rlsC6k7qqbJTpFxdeulBHvLVGfHWIs5zJX50lNyQMDL5/pub?embedded=true" title="Cultural Comparison Cantonese"></iframe>,
      downloadUrl: 'https://drive.google.com/uc?id=1K9p7pwWp22tLtKK9BpjLN-mtFBz4fNev&export=download',
      mainImage: { src: '/images/Product_Posters/Others/Cultural_Comparison_Cantonese/Cultural_Comparison_Cantonese.jpeg', altText: 'Chinese Cartoon' },
      smallImages: []
    },
    'resultative_complements': { 
      name: '完了wanle vs 好了 haole | Resultative Complements (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Others',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Writing'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/Others/GM_wanlehaole/wanlehaole_Instructions/1.png'} alt={'Chinese Cartoon'}/>
        <img src={'/images/Product_Posters/Others/GM_wanlehaole/wanlehaole_Instructions/2.png'} alt={'Mandarin Shadowing'}/>
        <img src={'/images/Product_Posters/Others/GM_wanlehaole/wanlehaole_Instructions/3.png'} alt={'Mandarin free resource'}/>
        <img src={'/images/Product_Posters/Others/GM_wanlehaole/wanlehaole_Instructions/4.png'} alt={'Mandarin homeschool'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=119Vf2ebPkl88UwTApAli46OOJoTd2Z1M&export=download',
      mainImage: { src: '/images/Product_Posters/Others/GM_wanlehaole/wanlehaole.jpeg', altText: 'Learn Mandarin by watching cartoon' },
      smallImages: []
    },
    'date_with_bathroom_simp': { 
      name: '我男友和厕所有个约会 My BF Has a Date with the Bathroom | Manga Series (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Others',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Writing'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/01.png'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/02.png'} alt={'Daily Mandarin'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/03.png'} alt={'Mandarin story telling'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/04.png'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/05.png'} alt={'Daily Mandarin'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/06.png'} alt={'Mandarin story telling'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/07.png'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/08.png'} alt={'Daily Mandarin'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/09.png'} alt={'Mandarin story telling'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/10.png'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/11.png'} alt={'Daily Mandarin'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/12.png'} alt={'Mandarin story telling'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/13.png'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Simp/14.png'} alt={'Daily Mandarin'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=169ya1-iHloL-fIl3yRUPDluw4xjj_Bgu&export=download',
      mainImage: { src: '/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/My_BF_Has_a_Date_with_the_Bathroom.jpeg', altText: 'Learn Mandarin by watching cartoon' },
      smallImages: []
    },
    'date_with_bathroom_trad': { 
      name: '我男友和厕所有个约会 My BF Has a Date with the Bathroom | Manga Series (Traditional)',
      language: ['Mandarin-Traditional'],
      productType: 'Others',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Writing'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/01.png'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/02.png'} alt={'Daily Mandarin'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/03.png'} alt={'Mandarin story telling'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/04.png'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/05.png'} alt={'Daily Mandarin'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/06.png'} alt={'Mandarin story telling'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/07.png'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/08.png'} alt={'Daily Mandarin'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/09.png'} alt={'Mandarin story telling'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/10.png'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/11.png'} alt={'Daily Mandarin'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/12.png'} alt={'Mandarin story telling'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/13.png'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/Trad/14.png'} alt={'Daily Mandarin'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1Z1_B16zg__BPk1R9-hgukgZ7DBI7_AwI&export=download',
      mainImage: { src: '/images/Product_Posters/Others/My_BF_Has_a_Date_with_the_Bathroom/My_BF_Has_a_Date_with_the_Bathroom.jpeg', altText: 'Learn Mandarin by watching cartoon' },
      smallImages: []
    },
    'hotel_robot': { 
      name: '酒店機械人 | Hotel Robot',
      language: ['Mandarin-Traditional', 'Mandarin-Simplified'],
      productType: 'Others',
      level: ['Beginner', 'Intermediate'],
      focus: ['Reading', 'Writing'],
      description: <div className={'left-align'}>
          <iframe src="https://docs.google.com/document/d/e/2PACX-1vR4FEG2VBGji8F-9M3Iuk8FvbL1uoPd58bCQqCAlJgSS3vyI0NEJ27vkQEBd3MYjVaF_09OJT-mf3zq/pub?embedded=true" title="Hotel Robot"></iframe>
          <p>
            🆂🅸🅼🅿🅻🅸🅵🅸🅴🅳<br/>
            回美国前的最后两天我还是羊了。<br/>
            为了不传染给父母，我搬进了四星级酒店。<br/>
            晚上肚子饿了，肯定要美团一下！<br/>
            这几年看到朋友都分享中国酒店的机械人有多厉害。<br/>
            今天我终于也能见识见识。<br/>
            到了。<br/>
            要输入房间号码才能取餐，抽屉关上了就会自己离开。<br/>
            “再见了 可爱的机械人！“<br/>
            顺便让你们看看我点的外卖吧。是肠粉和豆浆：）贼好吃。<br/>
          </p>
          <p>
            🆃🆁🅰🅳🅸🆃🅸🅾🅽🅰🅻<br/>
            回美國前的最後兩天我還是羊了。<br/>
            為了不傳染給父母，我搬進了四星級酒店。<br/>
            晚上肚子餓了，肯定要美團一下！<br/>
            這幾年看到朋友都分享中國酒店的機械人有多厲害。<br/>
            今天我終於也能見識見識。<br/>
            到了。<br/>
            要輸入房間號碼才能取餐，抽屜關上了就會自己離開。<br/>
            “再見了 可愛的機械人！“<br/>
            順便讓你們看看我點的外賣吧。是腸粉和豆漿：）賊好吃。<br/>
          </p>
          <p>
            🄿🄸🄽🅈🄸🄽<br/>
            Huí Měiguó qián de zuìhòu liǎng tiān wǒ háishì yáng le<br/>
            Wèile bù chuánrǎn gěi fùmǔ, wǒ bān jìn le sìxīngjí jiǔdiàn.<br/>
            Wǎnshàng dùzi è le, kěndìng yào měituán yíxià!<br/>
            Zhè jǐ nián kàndào péngyǒu dōu fēnxiǎng Zhōngguó jiǔdiàn de jīxièrén yǒu duō lìhài<br/>
            Jīntiān wǒ zhōngyú yě néng jiànshí jiànshí<br/>
            Dào le<br/>
            Yào shūrù fángjiān hàomǎ cái néng qǔcān<br/>
            Chōuti guān shàng le jiù huì zìjǐ líkāi<br/>
            "Zàijiàn le kě'ài de jīxièrén!"<br/>
            Shùnbiàn ràng nǐmen kànkan wǒ diǎn de wàimài ba. Shì chángfěn hé dòujiāng:）zéi hào chī.<br/>
          </p>
          <p>
            🄴🄽🄶🄻🄸🅂🄷<br/>
            In the last two days before returning to the United States, I caught Covid.<br/>
            To avoid infecting my parents, I moved into a 4 star hotel.<br/>
            I got hungry in the evening, so I definitely need to order some food delivery!<br/>
            In recent years, I've seen friends sharing how amazing the robots in Chinese hotels are.<br/>
            Today, I finally get to witness it myself.<br/>
            Here we are.<br/>
            You need to enter the room number to get the meal.<br/>
            Once you close the drawer, it will automatically leave on its own.<br/>
            "Goodbye, adorable robot!"<br/>
            By the way, let me show you the takeout I ordered. It's rice noodle rolls and soy milk. They're really delicious.<br/>
          </p>
          <p>
            🄺🄾🅁🄴🄰🄽<br/>
            미국으로 돌아가기 두 일 전에 코로나에 걸렸습니다.<br/>
            부모님에게 전염시키지 않기 위해, 나는 4성급 호텔로 이사했습니다.<br/>
            밤에 배가 고플 때, 반드시 미투안을 이용해야 했습니다!<br/>
            이 몇 년 동안 중국 호텔의 로봇이 얼마나 멋진지 친구들이 공유하는 것을 보았습니다.<br/>
            오늘 드디어 직접 경험할 수 있습니다.<br/>
            도착했습니다.<br/>
            방 번호를 입력해야 식사를 받을 수 있고, 서랍이 닫히면 스스로 떠납니다.<br/>
            "안녕, 귀여운 로봇!"<br/>
            그런데, 제가 주문한 외식을 보여드리겠습니다. 쌀국수 롤과 두유입니다 :) 정말 맛있습니다.<br/>
          </p>
          <p>
            🄹🄰🄿🄰🄽🄴🅂🄴<br/>
            アメリカに戻る2日前にコロナにかかりました。<br/>
            両親に感染を避けるため、四つ星ホテルに移りました。<br/>
            夜にお腹がすいた時、間違いなくMeituanで食事を頼むことになります！<br/>
            ここ数年間、友人たちは中国のホテルのロボットがどれほどすごいかを共有してきました。<br/>
            今日、私もついにその経験をすることができました。<br/>
            到着しました。<br/>
            部屋番号を入力してから食事を受け取り、引き出しが閉じたらロボットは自分で去ります。<br/>
            "さようなら、かわいいロボット！"<br/>
            ついでに、私が注文したテイクアウトを見てみましょう。それはチャーファン（米粉ロール）と豆乳です :) とてもおいしいです。<br/>
          </p>
        </div>,
      downloadUrl: '',
      mainImage: { src: '/images/Product_Posters/Others/Hotel_Robot/hotel_robot.png', altText: 'Daily Mandarin Short reading and writing exercises' },
      smallImages: [],
      instagramUrl: 'https://www.instagram.com/p/Cun-ulINz5m/'
    },
    'diy_workshop': { 
      name: '手作坊 | DIY Workshop',
      language: ['Mandarin-Traditional', 'Mandarin-Simplified'],
      productType: 'Others',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Writing'],
      description: <div className={'left-align'}>
        <iframe src="https://docs.google.com/document/d/e/2PACX-1vRJgtS89MA0rcerjWJszzNZK-SNGwE0mOSD9tsiq2Ox2RPFgpWqfB5DsPS8TSD0t6okdyTgIcXnKsIN/pub?embedded=true" title="diy_workshop"></iframe>
        <p>
          🆂🅸🅼🅿🅻🅸🅵🅸🅴🅳<br/>
          <b>DIY手作坊</b><br/>
          这天是男友的生日，我们去了体验灯具DIY！首先要选木头底座，接著要选字型和字，还有要刻的图案。开始刻了，刻好男友的了！有香香的木头味。再来，就是刻我的。我只刻了图案，没有文字。接下来就是选装饰物，然后就是不停的钉，不停的槌。之后就是安装灯泡，再之后就是接线。最后，完成了！这是我做的。这是我男友做的。你们比较喜欢哪个？<br/>
        </p>
        <p>
          🄿🄸🄽🅈🄸🄽<br/>
          DIY shǒuzuò fáng<br/>
          Zhè tiān shì nányǒu de shēngrì, wǒmen qùle tǐyàn dēngjù DIY! Shǒuxiān yào xuǎn mùtou dǐzuo, jīézhe yào xuǎn zìxíng hé zì。Háiyǒu yào kè de tú'àn. Kāishǐ kèle, kè hǒu nányǒu de le! Yǒu xiāngxiāng de mùtou wèi. Zàilái, jiùshì kè wǒ de. Wǒ zhǐ kèle tú'àn, méiyǒu wénzì。Zhīhòu jiùshì xuǎn zhuāngshìwù, ránhòu jiùshì bù tíng de dīng, bù tíng de chūi. Zuìhòu, wánchéngle! Zhè shì wǒ zuò de. Zhè shì nányǒu de le。Zhè shì nǐmen bǐjiào xǐhuan de。Zuìhòu, zhè shì nǐmen bǐjiào xǐhuan de。Niǐmen bǐjiào xǐhuan nǎgè?<br/>
        </p>
        <p>
          🄴🄽🄶🄻🄸🅂🄷<br/>
          DIY Workshop<br/>
          This day is the boyfriend's birthday, we went to experience DIY. First, we had to select the wooden base, then the typeface and letters, and finally, we needed to choose the pattern. After that, we had to select decorations, and then we had to hammer it without stopping. Afterward, we installed the bulb, and after that, we had to wire it. In the end, we got it! Which one do you prefer?<br/>
        </p>
        <p>
          🄺🄾🅁🄴🄰🄽<br/>
          DIY 손작업방<br/>
          이날은 남자친구의 생일이었고, 우리는 조명 DIY를 체험하러 갔습니다! 먼저 나무 베이스를 선택하고, 다음으로 글자체와 문자를 선택해야 했습니다. 그리고 새길 패턴도 선택해야 했습니다. 남자친구의 것을 새기기 시작했고, 향기로운 나무 냄새가 났습니다. 다음은 제 것을 새기는 것이었습니다. 저는 패턴만 새겼고, 문자는 새기지 않았습니다. 그 다음은 장식품을 선택하고, 그리고 계속해서 못을 박고, 망치를 계속 두드려야 했습니다. 그 다음은 전구를 설치하고, 그 다음은 전선을 연결하는 것이었습니다. 마지막으로, 완성했습니다! 이것은 제가 만든 것입니다. 이것은 남자친구가 만든 것입니다. 여러분은 어느 것을 더 좋아하시나요?<br/>
        </p>
        <p>
          🄹🄰🄿🄰🄽🄴🅂🄴<br/>
          DIY手作り工房<br/>
          この日は彼氏の誕生日で、私たちは照明器具のDIYを体験しに行きました！まずは木製のベースを選び、次に文字の形と文字を選びました。そして、彫るパターンも選びました。彼氏のものを彫り始めました！香りの良い木の匂いがしました。次に、私のものを彫りました。私はパターンだけを彫り、文字は彫りませんでした。次に装飾品を選び、そして止まることなく釘を打ち、ハンマーを打ち続けました。その後は電球を取り付け、その後は配線を行いました。最後に、完成しました！これは私が作ったものです。これは彼氏が作ったものです。あなた方はどちらが好きですか？<br/>
        </p>
      </div>,
      downloadUrl: '',
      mainImage: { src: '/images/Product_Posters/Others/Diy_Workshop/Workshop.png', altText: 'Mandarin fun speaking exercise for transition words sequence of events' },
      smallImages: [],
      instagramUrl: 'https://www.instagram.com/reel/CvK-nkdPtL5/'
    },
    'crossing_road': { 
      name: '过马路 Crossing the Road (Simplified)',
      language: '',
      productType: 'IB Oral Exam Practice',
      level: [],
      focus: [],
      description: <div>
        <div className="product-detail-header">
            <h2 className="product-name">{'过马路 Crossing the Road (Simplified)'}</h2>
        </div>
        <div className={'product-description-images'}>
          <img src={'/images/Product_Posters/IB/Published/Crossing_the_Road/1.jpeg'} alt={'Mandarin Shadowing'}/>
          <img src={'/images/Product_Posters/IB/Published/Crossing_the_Road/2.jpeg'} alt={'Learn Mandarin by watching cartoon'}/>
          <img src={'/images/Product_Posters/IB/Published/Crossing_the_Road/3.jpeg'} alt={'Mandarin Speaking Practice'}/>
        </div>
      </div>,
      downloadUrl: '',
      mainImage: { src: '/images/Product_Posters/IB/CrossingTheRoad.jpeg', altText: 'Peppa Pig Mandarin' },
      smallImages: []
    },
    'shopping_avenue': { 
      name: '购物大道 Shopping Avenue (Simplified)',
      language: '',
      productType: 'IB Oral Exam Practice',
      level: [],
      focus: [],
      description: <div>
        <div className="product-detail-header">
            <h2 className="product-name">{'购物大道 Shopping Avenue (Simplified)'}</h2>
        </div>
        <div className={'product-description-images'}>
          <img src={'/images/Product_Posters/IB/Published/Shopping_Avenue/1.jpeg'} alt={'Mandarin Shadowing'}/>
          <img src={'/images/Product_Posters/IB/Published/Shopping_Avenue/2.jpeg'} alt={'Peppa Pig Mandarin'}/>
          <img src={'/images/Product_Posters/IB/Published/Shopping_Avenue/3.jpeg'} alt={'Chinese Shadowing'}/>
        </div>
      </div>,
      downloadUrl: '',
      mainImage: { src: '/images/Product_Posters/IB/ShoppingAvenue.jpeg', altText: 'Mandarin free resource' },
      smallImages: []
    },
    'subway': { 
      name: '地铁 Subway (Simplified)',
      language: '',
      productType: 'IB Oral Exam Practice',
      level: [],
      focus: [],
      description: <div>
        <div className="product-detail-header">
            <h2 className="product-name">{'地铁 Subway (Simplified)'}</h2>
        </div>
        <div className={'product-description-images'}>
          <img src={'/images/Product_Posters/IB/Published/Subway/1.jpeg'} alt={'Mandarin Shadowing'}/>
          <img src={'/images/Product_Posters/IB/Published/Subway/2.jpeg'} alt={'Mandarin Speaking Practice'}/>
          <img src={'/images/Product_Posters/IB/Published/Subway/3.jpeg'} alt={'Mandarin homeschool'}/>
        </div>
      </div>,
      downloadUrl: '',
      mainImage: { src: '/images/Product_Posters/IB/Subway.jpeg', altText: 'Chinese Speaking Practice' },
      smallImages: []
    },
    'survival_mandarin_simplified': { 
      name: '求生中文 Survival Mandarin (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Daily Chinese',
      level: ['Beginner'],
      focus: ['Speaking', 'Reading'],
      description: <div>
        <h2 className="product-name">{'求生中文 Survival Mandarin (Simplified)'}</h2>
        <iframe src="/survival_mandarin_simplified/story.html" title="Survival Mandarin Simplified"></iframe>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1IpRqNVLh05MVOtjJvZlPDsB9vcckMb-h&export=download',
      mainImage: { src: '/images/Product_Posters/Everyday/Survival_SimplANDTrad/SurvivalMandarin.jpeg', altText: 'Chinese Shadowing' },
      smallImages: [
        { src: '/images/Product_Posters/Everyday/Survival_SimplANDTrad/info_SurvivalMandarin_EC/1.jpeg', altText: 'Mandarin Shadowing' },
        { src: '/images/Product_Posters/Everyday/Survival_SimplANDTrad/info_SurvivalMandarin_EC/2.jpeg', altText: 'Mandarin Speaking Practice' },
        { src: '/images/Product_Posters/Everyday/Survival_SimplANDTrad/info_SurvivalMandarin_EC/3.jpeg', altText: 'Mandarin homeschool' }
      ]
    },
    'survival_mandarin_traditional': { 
      name: '求生中文 Survival Mandarin (Traditional)',
      language: ['Mandarin-Traditional'],
      productType: 'Daily Chinese',
      level: ['Beginner'],
      focus: ['Speaking', 'Reading'],
      description: <iframe src="/survival_mandarin_traditional/story.html" title="Survival Mandarin Traditional"></iframe>,
      downloadUrl: 'https://drive.google.com/uc?id=1qQf9q58km_w9xoyOJjsHhzwM71EPuHWl&export=download',
      mainImage: { src: '/images/Product_Posters/Everyday/Survival_SimplANDTrad/SurvivalMandarin.jpeg', altText: 'Chinese Shadowing' },
      smallImages: [
        { src: '/images/Product_Posters/Everyday/Survival_SimplANDTrad/info_SurvivalMandarin_EC/1.jpeg', altText: 'Learn Mandarin by watching cartoon' },
        { src: '/images/Product_Posters/Everyday/Survival_SimplANDTrad/info_SurvivalMandarin_EC/2.jpeg', altText: 'Chinese Cartoon' },
        { src: '/images/Product_Posters/Everyday/Survival_SimplANDTrad/info_SurvivalMandarin_EC/3.jpeg', altText: 'Chinese Speaking Practice' }
      ]
    },
    'survival_mandarin_cantonese': { 
      name: '求生廣東話 Survival Cantonese (Traditional)',
      language: ['Cantonese'],
      productType: 'Daily Chinese',
      level: ['Beginner'],
      focus: ['Speaking', 'Reading'],
      description: <iframe src="/survival_cantonese_traditional/story.html" title="Survival Mandarin Cantonese"></iframe>,
      downloadUrl: 'https://drive.google.com/uc?id=18kALgVMysc-D_iCdB2RdZs61yrxnbWmN&export=download',
      mainImage: { src: '/images/Product_Posters/Everyday/Survival_Cantonese/SurvivalCantonese.jpeg', altText: 'Peppa Pig Mandarin' },
      smallImages: [
        { src: '/images/Product_Posters/Everyday/Survival_Cantonese/info_SurvivalCantonese_EC/1.jpeg', altText: 'Mandarin Shadowing' },
        { src: '/images/Product_Posters/Everyday/Survival_Cantonese/info_SurvivalCantonese_EC/2.jpeg', altText: 'Mandarin free resource' },
        { src: '/images/Product_Posters/Everyday/Survival_Cantonese/info_SurvivalCantonese_EC/3.jpeg', altText: 'Chinese Speaking Practice' }
      ]
    },
    'moodmeter_simplified': { 
      name: '情绪仪表 Mood Meter (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Daily Chinese',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Writing'],
      description: <iframe src="/moodmeter_mandarin_simplified/story.html" title="Mood Meter Simplified"></iframe>,
      downloadUrl: 'https://drive.google.com/uc?id=1uh-MiRkKglAUMzSuRVzJIOc04NKRbszY&export=download',
      mainImage: { src: '/images/Product_Posters/Everyday/MM_SimplANDTrad/MM.jpeg', altText: 'Peppa Pig Mandarin' },
      smallImages: [
        { src: '/images/Product_Posters/Everyday/MM_SimplANDTrad/info_MM_EC/1.jpeg', altText: 'Chinese Cartoon' },
        { src: '/images/Product_Posters/Everyday/MM_SimplANDTrad/info_MM_EC/2.jpeg', altText: 'Mandarin free resource' },
        { src: '/images/Product_Posters/Everyday/MM_SimplANDTrad/info_MM_EC/3.jpeg', altText: 'Learn Mandarin by watching cartoon' }
      ]
    },
    'moodmeter_traditional': { 
      name: '情緒儀表 Mood Meter (Traditional)',
      language: ['Mandarin-Traditional'],
      productType: 'Daily Chinese',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Writing'],
      description: <iframe src="/moodmeter_mandarin_traditional/story.html" title="Mood Meter Traditional"></iframe>,
      downloadUrl: 'https://drive.google.com/uc?id=1NhX1dPjM6mf1qbfGzjtaRb3DmqRDQZrA&export=download',
      mainImage: { src: '/images/Product_Posters/Everyday/MM_SimplANDTrad/MM.jpeg', altText: 'Mandarin Speaking Practice' },
      smallImages: [
        { src: '/images/Product_Posters/Everyday/MM_SimplANDTrad/info_MM_EC/1.jpeg', altText: 'Learn Mandarin by watching cartoon' },
        { src: '/images/Product_Posters/Everyday/MM_SimplANDTrad/info_MM_EC/2.jpeg', altText: 'Chinese Shadowing' },
        { src: '/images/Product_Posters/Everyday/MM_SimplANDTrad/info_MM_EC/3.jpeg', altText: 'Chinese Cartoon' }
      ]
    },
    'moodmeter_cantonese': { 
      name: '情緒儀表 Mood Meter (Traditional)',
      language: ['Cantonese'],
      productType: 'Daily Chinese',
      level: ['Beginner', 'Intermediate'],
      focus: ['Speaking', 'Reading', 'Writing'],
      description: <iframe src="/moodmeter_cantonese_traditional/story.html" title="Mood Meter Cantonese"></iframe>,
      downloadUrl: 'https://drive.google.com/uc?id=1_yztu0EmOsd9KooDjigsnS7m7A-nFbJF&export=download',
      mainImage: { src: '/images/Product_Posters/Everyday/MM_Cantonese/MM_Cantonese.jpeg', altText: 'Mandarin homeschool' },
      smallImages: [
        { src: '/images/Product_Posters/Everyday/MM_Cantonese/info_MMCantonese_EC/1.jpeg', altText: 'Mandarin Speaking Practice' },
        { src: '/images/Product_Posters/Everyday/MM_Cantonese/info_MMCantonese_EC/2.jpeg', altText: 'Mandarin Shadowing' },
        { src: '/images/Product_Posters/Everyday/MM_Cantonese/info_MMCantonese_EC/3.jpeg', altText: 'Chinese Cartoon' }
      ]
    },
    'weather_report': { 
      name: '天气预报 Weather Report (Simplied))',
      language: ['Mandarin-Simplified'],
      productType: 'Daily Chinese',
      level: ['Beginner'],
      focus: ['Speaking', 'Reading', 'Writing','Listening'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/Everyday/Weather_Report/1.jpeg'} alt={'Fun Mandarin speaking exercise'}/>
        <img src={'/images/Product_Posters/Everyday/Weather_Report/2.jpeg'} alt={'Daily Mandarin'}/>
        <img src={'/images/Product_Posters/Everyday/Weather_Report/3.jpeg'} alt={'Mandarin Chinese Weather Report Forecast'}/>
      </div>,
      downloadUrl: 'https://www.teacherspayteachers.com/Product/-Weather-Report-Activity-Simplified-10084119',
      mainImage: { src: '/images/Product_Posters/Everyday/Weather_Report/Weather.png', altText: 'Mandarin homeschool' },
      smallImages: [ ]
    },
    'sky_air_temperature': { 
      name: 'Sky - Air - Temperature - Warm - Heater - ?',
      language: ['Mandarin-Traditional', 'Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Beginner'],
      focus: ['Reading'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/Sky/1.jpeg'} alt={'Mandarin homeschool'}/>
        <img src={'/images/Product_Posters/WordChain/Sky/2.jpeg'} alt={'Mandarin Shadowing'}/>
        <img src={'/images/Product_Posters/WordChain/Sky/3.jpeg'} alt={'Mandarin free resource'}/>
        <img src={'/images/Product_Posters/WordChain/Sky/4.jpeg'} alt={'Peppa Pig Mandarin'}/>
        <img src={'/images/Product_Posters/WordChain/Sky/5.jpeg'} alt={'Chinese Speaking Practice'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=13imA0lj2IYznUTgsDR74_mnWBfOTKOG9&export=download',
      mainImage: { src: '/images/Product_Posters/WordChain/Sky/Sky.gif', altText: 'Chinese Cartoon' },
      smallImages: [],
      instagramUrl: 'https://www.instagram.com/reel/CuXdXO5g4ls'
    },
    'grocery_shopping': { 
      name: '买菜 Grocery Shopping (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Beginner', 'Intermediate'],
      focus: ['Reading'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/Grocery_Shopping/1.jpeg'} alt={'Chinese daily reading grocery'}/>
        <img src={'/images/Product_Posters/WordChain/Grocery_Shopping/2.jpeg'} alt={'Chinese must learn vocabulary grocery'}/>
        <img src={'/images/Product_Posters/WordChain/Grocery_Shopping/3.jpeg'} alt={'Mandarin grocery shopping'}/>
        <img src={'/images/Product_Posters/WordChain/Grocery_Shopping/4.jpeg'} alt={'Mandarin intermediate reading grocery'}/>
        <img src={'/images/Product_Posters/WordChain/Grocery_Shopping/5.jpeg'} alt={'Mandarin daily short reading grocery shopping'}/>
        <img src={'/images/Product_Posters/WordChain/Grocery_Shopping/6.jpeg'} alt={'Chinese Mandarin grocery vocabulary'}/>
        <img src={'/images/Product_Posters/WordChain/Grocery_Shopping/7.jpeg'} alt={'Chinese Mandarin intermediate 5 minutes short reading'}/>
      </div>,
      downloadUrl: '',
      mainImage: { src: '/images/Product_Posters/WordChain/Grocery_Shopping/GroceryShopping.png', altText: 'Daily Mandarin Reading' },
      smallImages: [],
      instagramUrl: 'https://www.instagram.com/reel/CvMLVsbtKvz/'
    },
    'parking_lot': { 
      name: '停车场 Parking Lot (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Beginner', 'Intermediate'],
      focus: ['Reading'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/ParkingLot/1.png'} alt={'Chinese daily reading grocery'}/>
        <img src={'/images/Product_Posters/WordChain/ParkingLot/2.png'} alt={'Chinese must learn vocabulary parking car'}/>
        <img src={'/images/Product_Posters/WordChain/ParkingLot/3.png'} alt={'Mandarin parking driving vocabulary'}/>
        <img src={'/images/Product_Posters/WordChain/ParkingLot/4.png'} alt={'Mandarin intermediate reading car'}/>
        <img src={'/images/Product_Posters/WordChain/ParkingLot/5.png'} alt={'Mandarin daily short reading parking padestrian shopping'}/>
        <img src={'/images/Product_Posters/WordChain/ParkingLot/6.png'} alt={'Chinese Mandarin parking space vocabulary'}/>
        <img src={'/images/Product_Posters/WordChain/ParkingLot/7.png'} alt={'Chinese Mandarin intermediate 5 minutes short reading'}/>
        <img src={'/images/Product_Posters/WordChain/ParkingLot/8.png'} alt={'Chinese Mandarin intermediate 5 minutes short reading guide'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1Z8Wv5R-1q43jSCbdlq6W6TaWa1cxlFFO&export=download',
      mainImage: { src: '/images/Product_Posters/WordChain/ParkingLot/ParkingLot.png', altText: 'Daily Mandarin Reading' },
      smallImages: [],
      instagramUrl: 'https://www.instagram.com/p/Cvntm1itVB5/'
    },
    'fast_food': { 
      name: '快餐 Fast Food (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Beginner', 'Intermediate'],
      focus: ['Reading'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/FastFood/01.png'} alt={'Chinese daily reading grocery'}/>
        <img src={'/images/Product_Posters/WordChain/FastFood/02.png'} alt={'Chinese must learn vocabulary parking car'}/>
        <img src={'/images/Product_Posters/WordChain/FastFood/03.png'} alt={'Mandarin fast food vocabulary'}/>
        <img src={'/images/Product_Posters/WordChain/FastFood/04.png'} alt={'Mandarin intermediate reading car'}/>
        <img src={'/images/Product_Posters/WordChain/FastFood/05.png'} alt={'Mandarin daily short reading hamburger soft drinks dries'}/>
        <img src={'/images/Product_Posters/WordChain/FastFood/06.png'} alt={'Chinese Mandarin fast food junk food vocabulary'}/>
        <img src={'/images/Product_Posters/WordChain/FastFood/07.png'} alt={'Chinese Mandarin intermediate 5 minutes short reading'}/>
        <img src={'/images/Product_Posters/WordChain/FastFood/08.png'} alt={'Chinese Mandarin beginners 5 minutes short reading guide'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1l8Iv0QI6kVps8e9_-IVKN2m2VVswK8bj&export=download',
      mainImage: { src: '/images/Product_Posters/WordChain/FastFood/FastFood.png', altText: 'Daily Mandarin Reading' },
      smallImages: [],
      instagramUrl: 'https://www.instagram.com/p/CvvuHlPNzKe/'
    },
    'costco': { 
      name: '开市客 Costco (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Beginner', 'Intermediate'],
      focus: ['Reading'],
      description: <iframe src="https://docs.google.com/document/d/e/2PACX-1vSZ1HDoXSt4flOYavH8iGnHYukwoUDnDDmWuSFxybAkfg8xJHVNefcZdPhfTEBHf2s9857eYDjEX8bi/pub?embedded=true" className="long-iframe" title="Costco"></iframe>,
      downloadUrl: '',
      mainImage: { src: '/images/Product_Posters/WordChain/Costco/Costco.gif', altText: 'Daily Mandarin Reading' },
      smallImages: [],
      instagramUrl: 'https://www.instagram.com/p/CuavGHksiC1'
    },
    'cat_pet': { 
      name: '养猫 Having a Cat (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Beginner', 'Intermediate'],
      focus: ['Reading'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/Cat_Pet/01.png'} alt={'Chinese daily reading grocery'}/>
        <img src={'/images/Product_Posters/WordChain/Cat_Pet/02.png'} alt={'Chinese must learn vocabulary parking car'}/>
        <img src={'/images/Product_Posters/WordChain/Cat_Pet/03.png'} alt={'Mandarin fast food vocabulary'}/>
        <img src={'/images/Product_Posters/WordChain/Cat_Pet/04.png'} alt={'Mandarin intermediate reading car'}/>
        <img src={'/images/Product_Posters/WordChain/Cat_Pet/05.png'} alt={'Mandarin daily short reading hamburger soft drinks dries'}/>
        <img src={'/images/Product_Posters/WordChain/Cat_Pet/06.png'} alt={'Chinese Mandarin fast food junk food vocabulary'}/>
        <img src={'/images/Product_Posters/WordChain/Cat_Pet/07.png'} alt={'Chinese Mandarin intermediate 5 minutes short reading'}/>
        <img src={'/images/Product_Posters/WordChain/Cat_Pet/08.png'} alt={'Chinese Mandarin beginners 5 minutes short reading guide'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=12kuF_-r4fi5HV41AjRj9fHIycwMLSMv2&export=download',
      mainImage: { src: '/images/Product_Posters/WordChain/Cat_Pet/Cat_Pet.png', altText: 'Daily Mandarin Reading' },
      smallImages: [],
      instagramUrl: 'https://www.instagram.com/p/CwBcn5SIE3N/'
    },
    'period_cramps': { 
      name: '大姨妈来了 Aunt Flo has come to visit (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Beginner', 'Intermediate'],
      focus: ['Reading'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/Period_Cramps/01.png'} alt={'Chinese daily reading Period Cramps'}/>
        <img src={'/images/Product_Posters/WordChain/Period_Cramps/02.png'} alt={'Chinese must learn vocabulary body health'}/>
        <img src={'/images/Product_Posters/WordChain/Period_Cramps/03.png'} alt={'Mandarin hsk7 hsk8 native expressions'}/>
        <img src={'/images/Product_Posters/WordChain/Period_Cramps/04.png'} alt={'Mandarin intermediate reading practical expressions daily'}/>
        <img src={'/images/Product_Posters/WordChain/Period_Cramps/05.png'} alt={'Mandarin daily short reading vocabulary not in the textbook'}/>
        <img src={'/images/Product_Posters/WordChain/Period_Cramps/06.png'} alt={'Chinese Mandarin health daily hsk2 hsk3 hsk4'}/>
        <img src={'/images/Product_Posters/WordChain/Period_Cramps/07.png'} alt={'Chinese Mandarin intermediate 5 minutes short reading'}/>
        <img src={'/images/Product_Posters/WordChain/Period_Cramps/08.png'} alt={'Chinese Mandarin beginners 5 minutes short reading guide'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1vTOlpmwZ7QNuwgtAggDwzD43PA2mOPgO&export=download',
      mainImage: { src: '/images/Product_Posters/WordChain/Period_Cramps/Period_Cramps.png', altText: 'Daily Mandarin Easy Reading' },
      smallImages: [],
      instagramUrl: 'https://www.instagram.com/p/CwTc2MOPu0m/'
    },
    'coffee': { 
      name: '去喝星巴克 Go ge Starbucks (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Beginner', 'Intermediate'],
      focus: ['Reading','Listening','Writing','Speaking'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/Coffee/vocabfull.png'} alt={'Chinese daily reading Coffee beverages'}/>
        <img src={'/images/Product_Posters/WordChain/Coffee/003.png'} alt={'Chinese must learn vocabulary coffee drinks'}/>
        <img src={'/images/Product_Posters/WordChain/Coffee/06.png'} alt={'Mandarin hsk1 hsk2 hsk3 native expressions'}/>
        <img src={'/images/Product_Posters/WordChain/Coffee/09.png'} alt={'Mandarin intermediate reading practical expressions daily'}/>
        <img src={'/images/Product_Posters/WordChain/Coffee/12.png'} alt={'Mandarin daily short reading how to order in Chinese Mandarin'}/>
        <img src={'/images/Product_Posters/WordChain/Coffee/15.png'} alt={'Chinese Mandarin health daily hsk2 hsk3 hsk4'}/>
        <img src={'/images/Product_Posters/WordChain/Coffee/18.png'} alt={'Chinese Mandarin guided reading for beginners read after me quiz'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1rJNa4fJzq78BfLxhGsMfxa3_E8Il6Kjy&export=download=sharing',
      mainImage: { src: '/images/Product_Posters/WordChain/Coffee/website_cover.png', altText: 'Daily Mandarin useful Reading' },
      smallImages: [],
      instagramUrl: ''
    },
    'GenZ': { 
      name: '00后的兼职生活 GenZ part-time life (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Intermediate','Advanced'],
      focus: ['Reading','Listening','Writing','Speaking'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/GenZ/vocabfull.png'} alt={'Chinese daily reading GenZ part-time'}/>
        <img src={'/images/Product_Posters/WordChain/GenZ/03.png'} alt={'Chinese must learn vocabulary part-time money work'}/>
        <img src={'/images/Product_Posters/WordChain/GenZ/06R.png'} alt={'Mandarin hsk1 hsk2 hsk3 native expressions'}/>
        <img src={'/images/Product_Posters/WordChain/GenZ/09.png'} alt={'Mandarin intermediate reading practical expressions daily'}/>
        <img src={'/images/Product_Posters/WordChain/GenZ/12.png'} alt={'Mandarin daily short reading how to order in Chinese Mandarin'}/>
        <img src={'/images/Product_Posters/WordChain/GenZ/15.png'} alt={'Chinese Mandarin health daily hsk2 hsk3 hsk4'}/>
        <img src={'/images/Product_Posters/WordChain/GenZ/18.png'} alt={'Chinese Mandarin guided reading for intermediate and advanced learners'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1Fo3vgM3AG5Ls6s2lgq_-doQ2ubhvjzzV&export=download',
      mainImage: { src: '/images/Product_Posters/WordChain/GenZ/website_cover.png', altText: 'Daily Mandarin useful Reading' },
      smallImages: [],
      instagramUrl: 'https://www.instagram.com/p/Cw2skIvLUX3/?img_index=1'
    },
    'student_vlog': { 
      name: '初二年级学生日常vlog 8th Grader Daily vlog (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Intermediate','Advanced'],
      focus: ['Reading','Listening','Writing','Speaking'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/student_vlog/vocabfull.png'} alt={'Chinese daily reading GenZ part-time'}/>
        <img src={'/images/Product_Posters/WordChain/student_vlog/03.png'} alt={'Chinese must learn vocabulary part-time money work'}/>
        <img src={'/images/Product_Posters/WordChain/student_vlog/06.png'} alt={'Mandarin hsk1 hsk2 hsk3 native expressions'}/>
        <img src={'/images/Product_Posters/WordChain/student_vlog/09.png'} alt={'Mandarin intermediate reading practical expressions daily'}/>
        <img src={'/images/Product_Posters/WordChain/student_vlog/12.png'} alt={'Mandarin daily short reading how to order in Chinese Mandarin'}/>
        <img src={'/images/Product_Posters/WordChain/student_vlog/15.png'} alt={'Chinese Mandarin health daily hsk2 hsk3 hsk4'}/>
        <img src={'/images/Product_Posters/WordChain/student_vlog/18.png'} alt={'Chinese Mandarin guided reading redbook vlog'}/>
        <img src={'/images/Product_Posters/WordChain/student_vlog/21.png'} alt={'Chinese Mandarin take out fried noodle'}/>
        <img src={'/images/Product_Posters/WordChain/student_vlog/24.png'} alt={'Chinese Mandarin variety show'}/>
        <img src={'/images/Product_Posters/WordChain/student_vlog/27.png'} alt={'Chinese Mandarin hsk8 non textbook Chinese'}/>
        <img src={'/images/Product_Posters/WordChain/student_vlog/30.png'} alt={'Chinese Mandarin social media posts video comment'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1mWcdg57cwb7gypm2nZpkeAieT_vfMSfd&export=download',
      mainImage: { src: '/images/Product_Posters/WordChain/student_vlog/cover.png', altText: 'Daily Mandarin young people expressions reading listening speaking writing' },
      smallImages: [],
      instagramUrl: ''
    },
    'couple_interview': { 
      name: '街头情侣采访 Couple Street Interview (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Beginner','Intermediate'],
      focus: ['Reading','Listening','Writing','Speaking'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/couple_interview/vocabfull.png'} alt={'Chinese daily reading reels interview short'}/>
        <img src={'/images/Product_Posters/WordChain/couple_interview/03.png'} alt={'Chinese must learn vocabulary part-time money work'}/>
        <img src={'/images/Product_Posters/WordChain/couple_interview/06.png'} alt={'Mandarin hsk1 hsk2 hsk3 native expressions'}/>
        <img src={'/images/Product_Posters/WordChain/couple_interview/09.png'} alt={'Mandarin intermediate reading practical expressions daily'}/>
        <img src={'/images/Product_Posters/WordChain/couple_interview/12.png'} alt={'Mandarin daily short reading how to order in Chinese Mandarin'}/>
        <img src={'/images/Product_Posters/WordChain/couple_interview/15.png'} alt={'Chinese Mandarin health daily hsk2 hsk3 hsk4 hsk5'}/>
        <img src={'/images/Product_Posters/WordChain/couple_interview/18.png'} alt={'Chinese Mandarin boyfriend girlfriend dating'}/>
        <img src={'/images/Product_Posters/WordChain/couple_interview/21.png'} alt={'Chinese Mandarin couple drama'}/>
        <img src={'/images/Product_Posters/WordChain/couple_interview/24.png'} alt={'Chinese Mandarin street interview'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1C4g6Da-tMeziXX-nzeQ_CEVy1765Dnsi&export=download',
      mainImage: { src: '/images/Product_Posters/WordChain/couple_interview/cover.png', altText: 'Daily Mandarin young people expressions reading listening speaking writing' },
      smallImages: [],
      instagramUrl: ''
    },
    'flexible_employment': { 
      name: '灵活就业 Flexible Employment (Simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Advanced','Intermediate'],
      focus: ['Reading','Listening','Writing','Speaking'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/flexible_employment/vocabfull.png'} alt={'Chinese daily reading advanced level'}/>
        <img src={'/images/Product_Posters/WordChain/flexible_employment/03.png'} alt={'Chinese must learn vocabulary hsk4 hsk5 hsk6 hsk7 hsk8'}/>
        <img src={'/images/Product_Posters/WordChain/flexible_employment/06.png'} alt={'Mandarin hsk4 hsk5 hsk6 hsk7 hsk8 native expressions'}/>
        <img src={'/images/Product_Posters/WordChain/flexible_employment/09.png'} alt={'Mandarin intermediate reading practical expressions daily'}/>
        <img src={'/images/Product_Posters/WordChain/flexible_employment/12.png'} alt={'Mandarin daily short reading China social problem'}/>
        <img src={'/images/Product_Posters/WordChain/flexible_employment/15.png'} alt={'Chinese Mandarin occupation rat race lying flat'}/>
        <img src={'/images/Product_Posters/WordChain/flexible_employment/18.png'} alt={'Chinese Mandarin unemployment rate news vocabulary'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1_z0lctlKolqZJAcHDTJ5lTYnb-NdDscf&export=download',
      mainImage: { src: '/images/Product_Posters/WordChain/flexible_employment/cover.png', altText: 'Daily Mandarin young people expressions reading listening speaking writing' },
      smallImages: [],
      instagramUrl: ''
    },
    'fall_sick': { 
      name: '一秋天我就生病了 It is Fall and I am sick (simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Beginner','Intermediate'],
      focus: ['Reading','Listening','Writing','Speaking'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/fall_sick/vocabfull.png'} alt={'Chinese daily reading beginner level'}/>
        <img src={'/images/Product_Posters/WordChain/fall_sick/03.png'} alt={'Chinese must learn vocabulary hsk1 hsk2 hsk3 hsk4 hsk5'}/>
        <img src={'/images/Product_Posters/WordChain/fall_sick/06.png'} alt={'Mandarin see a doctor native expressions'}/>
        <img src={'/images/Product_Posters/WordChain/fall_sick/09.png'} alt={'Mandarin intermediate reading practical expressions daily'}/>
        <img src={'/images/Product_Posters/WordChain/fall_sick/12.png'} alt={'Mandarin daily short reading China daily conversation'}/>
        <img src={'/images/Product_Posters/WordChain/fall_sick/15.png'} alt={'Chinese Mandarin four seasons'}/>
        <img src={'/images/Product_Posters/WordChain/fall_sick/18.png'} alt={'Chinese Mandarin intermediate reading and writing doctor medicine'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1mc7QDNRErb4XSUPEgNnjsR60gU0QqNWN&export=download',
      mainImage: { src: '/images/Product_Posters/WordChain/fall_sick/cover.png', altText: 'Daily Mandarin young people expressions reading listening speaking writing' },
      smallImages: [],
      instagramUrl: ''
    },
    'elem_kids': { 
      name: '小学生眼中的大学生  | College Students in the Eyes of Elem Kids (simplified)',
      language: ['Mandarin-Simplified'],
      productType: 'Word Chain',
      level: ['Beginner','Intermediate'],
      focus: ['Reading','Listening','Writing','Speaking'],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/WordChain/elem_kids/vocabfull.png'} alt={'Chinese daily reading beginner level'}/>
        <img src={'/images/Product_Posters/WordChain/elem_kids/02.png'} alt={'Chinese must learn vocabulary hsk1 hsk2 hsk3 hsk4 hsk5'}/>
        <img src={'/images/Product_Posters/WordChain/elem_kids/03.png'} alt={'Mandarin social media posts real life Chinese'}/>
        <img src={'/images/Product_Posters/WordChain/elem_kids/05.png'} alt={'Mandarin intermediate reading practical expressions daily'}/>
        <img src={'/images/Product_Posters/WordChain/elem_kids/06.png'} alt={'Mandarin daily short reading China daily conversation'}/>
        <img src={'/images/Product_Posters/WordChain/elem_kids/08.png'} alt={'Chinese Mandarin street interview guided reading'}/>
        <img src={'/images/Product_Posters/WordChain/elem_kids/09.png'} alt={'Chinese Mandarin intermediate redbook xiaohongshu Chinese'}/>
        <img src={'/images/Product_Posters/WordChain/elem_kids/11.png'} alt={'Chinese Mandarin intermediate Chinese Mandarin Beginners'}/>
        <img src={'/images/Product_Posters/WordChain/elem_kids/12.png'} alt={'Chinese Mandarin intermediate Mandarin HSK3 HSK2 Grammar conversation'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1BcUX2128gfgZegzYo0yBQdeqXC1LFCKD&export=download',
      mainImage: { src: '/images/Product_Posters/WordChain/elem_kids/cover.png', altText: 'Daily Mandarin young people expressions reading listening speaking writing' },
      smallImages: [],
      instagramUrl: ''
    },
    'mid_autumn_festival': { 
      name: '中秋 | Mid-Autumn Festival',
      language: '',
      productType: 'Coloring',
      level: [],
      focus: [],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/Coloring/Mid_Autum_Festival/1.png'} alt={'Free China Mid-Autumn Festival coloring page'}/>
        <img src={'/images/Product_Posters/Coloring/Mid_Autum_Festival/2.png'} alt={'Free Chinese lantern coloring page'}/>
        <img src={'/images/Product_Posters/Coloring/Mid_Autum_Festival/3.png'} alt={'Free Chinese lantern coloring page'}/>
        <img src={'/images/Product_Posters/Coloring/Mid_Autum_Festival/4.png'} alt={'Free Chinese lantern coloring page'}/>
        <img src={'/images/Product_Posters/Coloring/Mid_Autum_Festival/5.png'} alt={'Free Chinese lantern coloring page'}/>
        <img src={'/images/Product_Posters/Coloring/Mid_Autum_Festival/6.png'} alt={'Free China Mid-Autumn Festival coloring page rabbits and full moon'}/>
        <img src={'/images/Product_Posters/Coloring/Mid_Autum_Festival/7.png'} alt={'Free China Mid-Autumn Festival coloring page change and full moon'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1dMZ9eTI5LPXXC7SS3LxPPeLV1HTHtDfi&export=download',
      mainImage: { src: '/images/Product_Posters/Coloring/Mid_Autum_Festival/MidAutumnFest.jpeg', altText: 'Chinese Shadowing' },
      smallImages: []
    },
    'treasure_animals': { 
      name: '中國國寶級動物 | Chinese national treasure animals',
      language: '',
      productType: 'Coloring',
      level: [],
      focus: [],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/Coloring/Animals/BaijiDolphin.png'} alt={'Free coloring page dolphin Chinese'}/>
        <img src={'/images/Product_Posters/Coloring/Animals/BaijiDolphin2.png'} alt={'Free coloring page Chinese Baiji dolphin'}/>
        <img src={'/images/Product_Posters/Coloring/Animals/Golden_snub_nosed_monkey.png'} alt={'Free coloring page China Golden snub-nosed monkey'}/>
        <img src={'/images/Product_Posters/Coloring/Animals/Panda1.png'} alt={'Free coloring page Chinese treasure animal Panda'}/>
        <img src={'/images/Product_Posters/Coloring/Animals/Panda2.png'} alt={'Free coloring page baby Panda'}/>
        <img src={'/images/Product_Posters/Coloring/Animals/SiBuXiang.png'} alt={'Free coloring page Chinese animal SiBuXiang'}/>
        <img src={'/images/Product_Posters/Coloring/Animals/SouthChinaTiger.png'} alt={'Free coloring page South China Tiger'}/>
        <img src={'/images/Product_Posters/Coloring/Animals/TibetanAntelope.png'} alt={'Free coloring page Chinese Tibetan Antelope'}/>
        <img src={'/images/Product_Posters/Coloring/Animals/YangtzeAlligator.png'} alt={'Free coloring page Chinese YangtzeAlligator'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1-rOy70XFR0S28YLj5IIQLXv2LUWXLVUU&export=download',
      mainImage: { src: '/images/Product_Posters/Coloring/Animals/ChinaAnimals.png', altText: 'Learn Mandarin by watching cartoon' },
      smallImages: []
    },
    'classroom': { 
      name: '教室 | Classroom ',
      language: '',
      productType: 'Coloring',
      level: [],
      focus: [],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/Coloring/Classroom/Classroom1.png'} alt={'Free Chinese Classroom coloring page'}/>
        <img src={'/images/Product_Posters/Coloring/Classroom/Classroom2.png'} alt={'Free Chinese Classroom uniform classmates vocabulary'}/>
        <img src={'/images/Product_Posters/Coloring/Classroom/Classroom3.png'} alt={'Chinese uniform classmate chairs whiteboard vocabulary'}/>
        <img src={'/images/Product_Posters/Coloring/Classroom/Classroom4.png'} alt={'Free Chinese writing worksheet'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1ewBywr6YoGe6g2k41LAEq5f1IRAg_Ora&export=download',
      mainImage: { src: '/images/Product_Posters/Coloring/Classroom/ClassroomCover.jpeg', altText: 'Chinese Writing' },
      smallImages: []
    },
    'halloween_pages': { 
      name: '万圣节 | Halloween ',
      language: '',
      productType: 'Coloring',
      level: [],
      focus: [],
      description: <div className={'product-description-images'}>
        <img src={'/images/Product_Posters/Coloring/halloween_pages/1.png'} alt={'Free Chinese Halloween coloring page'}/>
        <img src={'/images/Product_Posters/Coloring/halloween_pages/2.png'} alt={'Free Chinese halloween candy vampire pumpkin vocabulary'}/>
        <img src={'/images/Product_Posters/Coloring/halloween_pages/3.png'} alt={'Chinese holiday festival halloween vocabulary'}/>
        <img src={'/images/Product_Posters/Coloring/halloween_pages/4.png'} alt={'Free Chinese writing worksheet'}/>
      </div>,
      downloadUrl: 'https://drive.google.com/uc?id=1rtyVJAhxkydXJwq-Vq9L1dc0MBSZUNHs&export=download',
      mainImage: { src: '/images/Product_Posters/Coloring/halloween_pages/Halloween_cover.png', altText: 'Chinese Writing' },
      smallImages: []
    }
  };

  let { productIndex } = useParams();
  const [showPopup, setShowPopup] = useState(productIndex && productList.hasOwnProperty(productIndex) ? true : false);
  const [showAdditionalProductPopup, setShowAdditionalProductPopup] = useState(false);
  const [productType, setProductType] = useState(productIndex && productList.hasOwnProperty(productIndex) ? productList[productIndex].productType : '');
  const [productId, setProductId] = useState(productIndex);

  const handleItemClick = (productType) => {
    setShowPopup(true);
    setProductType(productType);
  };

  const handleAdditionalProductClick = () => {
    setShowAdditionalProductPopup(true);
  }

  const handleClosePopup = () => {
    setShowPopup(false);
    setProductType('');
    setProductId(null);
  };

  const handleCloseAdditionalProductPopup = () => {
    setShowAdditionalProductPopup(false);
  };

  return (
    <div className="body-container">
      <div className="body-item-list-container">
        <div className="body-item-list">
          <BodyItem imageSrc={'/images/Catagories/PNG/1.png'} gifSrc={'/images/Catagories/GIF/1.gif'} onClickItem={handleItemClick} productType={"Daily Chinese"} />
          <BodyItem imageSrc={'/images/Catagories/PNG/2.png'} gifSrc={'/images/Catagories/GIF/2.gif'} onClickItem={handleItemClick} productType={"IB Oral Exam Practice"} />
          <BodyItem imageSrc={'/images/Catagories/PNG/3.png'} gifSrc={'/images/Catagories/GIF/3.gif'} onClickItem={handleItemClick} productType={"Word Chain"} />
        </div>
      </div>
      <div className="body-item-list-container">
        <div className="body-item-list">
          <BodyItem imageSrc={'/images/Catagories/PNG/4.png'} gifSrc={'/images/Catagories/GIF/4.gif'} onClickItem={handleItemClick} productType={"Others"} />
          <BodyItem imageSrc={'/images/Catagories/PNG/5.png'} gifSrc={'/images/Catagories/GIF/5.gif'} onClickItem={handleItemClick} productType={"Shadowing"} />
          <BodyItem imageSrc={'/images/Catagories/PNG/6.png'} gifSrc={'/images/Catagories/GIF/6.gif'} onClickItem={handleItemClick} productType={"Coloring"} />
        </div>
      </div>
      {/* <div className="additional-body-item-container">
        <AdditionalBodyItem title="Peripheral products" imageSrc={logo} link="#" onClickItem={handleAdditionalProductClick} />
        <AdditionalBodyItem title="Group Lesson" imageSrc={logo} link="#" onClickItem={handleAdditionalProductClick}/>
        <AdditionalBodyItem title="Writing Correction" imageSrc={logo} link="#" onClickItem={handleAdditionalProductClick}/>
      </div> */}
      {showPopup && <Popup showPopup={showPopup} onClose={handleClosePopup} productList={productList} productType={productType} productId={productId} />}
      {showAdditionalProductPopup && <AdditionalProductPopup showPopup={showAdditionalProductPopup} onClose={handleCloseAdditionalProductPopup}/>}
    </div>
  );
};

export default Body;
